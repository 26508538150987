import axios from "@axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {


    GetItem() {
        return new Promise((resolve, reject) => {

          axios
            .get(`/api/v1/item`)
            .then(response =>

              resolve(response.data.data)
              )
            .catch(error => reject(error))
        })

      },
      fetchDonor(ctx,queryParams) {
      return new Promise((resolve, reject) => {
axios.get(`/api/v1/donor`,{params:queryParams})
          .then(response =>resolve(response))
          .catch(error => reject(error))
      })

    },
    getEmployy(ctx,id) {
        return new Promise((resolve, reject) => {

          axios
            .get(`/api/v1/main_services/${id}/employees`)
            .then(response =>

              resolve(response)
              )
            .catch(error => reject(error))
        })

      },
 
    fetchListrequestAuditing(ctx,queryParams) {
     
    return new Promise((resolve, reject) => {

      
     //console.log(queryParams)
      axios
        .get("/api/v1/service_maintainer/coordinator/offered_services", { params: queryParams })
        .then((response) => {

          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

   
  Createdonor(ctx,payload) {
      return new Promise((resolve, reject) => {

      let url=`/api/v1/donor`
        axios
          .post(url,payload)
          .then((response) => {

            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
  },
};
